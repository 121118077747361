.projects {
  padding-bottom: 2rem;
  min-height: 100vh;
}
.projects img {
  /* border-radius: 5px; */
}
.projects-header {
  font-size: 2rem;
  margin-bottom: 0rem;
}
.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.projects-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 2rem;
  font-size: 1.5rem;
}
.projects-container a {
  text-decoration: none;
}
.projects-container img {
  max-width: 100%;
}

.each-project-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 30px;
}
.each-project-container p {
  font-size: 1rem;
  margin-top: 0rem;
}
.each-project-container img {
  max-width: 100%;
}
.each-project-container h4 {
  margin-bottom: 0.75rem;
  text-align: left;
}
.dark .each-project-container h4 {
  color: white;
}
.light .each-project-container h4 {
  color: black;
}
.each-project-container p {
  text-align: center;
  margin: 0 auto;
}
@media only screen and (min-width: 600px) {
  .projects-container {
    width: 80%;
    height: 80%;
    border: 0.5px solid rgb(66, 66, 66);
    border-radius: 5px;
  }
  .each-project-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .projects-container img {
    max-width: 100%;
  }
  .projects-container img:hover {
    opacity: 0.8;
  }
}
@media only screen and (min-width: 1000px) {
  .projects-container {
    min-height: 40rem;
  }
  .projects-container img {
    max-width: 100%;
  }
  .projects-header {
    font-size: 2.4rem;
    margin-top: 0.6rem;
  }
}
@media only screen and (min-width: 1600px) {
  .projects-container {
    min-height: 50rem;
  }
  .each-project-container {
    margin-top: -1rem;
  }
  .projects-header {
    margin-top: 1rem;
  }
  .projects-content {
    margin-top: 1.5rem;
  }
}
@media only screen and (max-width: 1200px) and (min-height: 900px) {
  .projects-container {
    margin-top: 2rem;
  }
}
