.secret {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  transition: 400ms;
}
.secret-container {
  width: 80%;
  margin-top: 2rem;
  border: 0.5px solid rgb(66, 66, 66);
  border-radius: 5px;
  max-height: 50rem;
  transition: 400ms;
}
.secret p {
  color: red;
}
form {
  display: flex;
  flex-direction: column;
  align-items: center;
}
input {
  border: 1px solid black;
  border-radius: 5px;
  text-align: center;
}
button {
  min-width: 26.6rem;
  color: white;
  border: none;
  border-radius: 5px;
  height: 3rem;
}

.secret-light {
  background-color: white;
}
.secret-dark {
  background-color: rgb(34, 34, 34);
}
.secret-light p {
  color: black;
}
.secret-dark p {
  color: white;
}
@media only screen and (max-width: 600px) {
  .secret-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 30rem;
  }
  .secret-container p {
    width: 90%;
  }

  input {
    /* min-width: 17rem; */
    max-width: 17rem;
    height: 2rem;
  }
  button {
    min-width: 17.6rem;
    max-width: 17.6rem;
    height: 3rem;
  }
  input,
  select,
  textarea {
    font-size: 16px;
  }
}
