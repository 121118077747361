.photo-modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    width: 10vw;
    z-index: 30;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    opacity: 0;
    transition: all 400ms;
}
.photo-modal-container.mounted {
    opacity: 1;
    width: 70vw;
}
.dark .photo-modal-container  {
    background-color: rgb(34, 34, 34);
    box-shadow: 1px 1px 3px rgb(66, 66, 66);
    border:1px solid rgb(66, 66, 66);
}
.photo-modal-container.light {
    background-color: #fff;
    box-shadow: 1px 1px 3px rgb(155, 155, 155);
}
.photo-modal-container img {
    border-radius: 5px;
    max-width: 100%;
    max-height: 90vh;
}
.close-photo-modal {
    position: absolute;
    color: #fff;
    top: 6px;
    right: 6px;
}
.light .close-photo-modal {
    color: rgb(34, 34, 34);
}
@media only screen and (max-width: 600px) {
    .photo-modal-container {
      display: none;
    }
  }
@media only screen and (max-width: 1400px) {
    .photo-modal-container.mounted {
      width: 80vw;
    }
}
@media only screen and (max-width: 1000px) {
    .photo-modal-container.mounted {
      width: 90vw;
    }
}