.edit-modal-container {
  border: 0.5px solid rgb(66, 66, 66);
  border-radius: 5px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 14rem;
  z-index: 10;
  box-shadow: 1px 1px 8px rgb(66, 66, 66);
}
.edit-modal-container input {
  margin-top: 24px;
}
.edit-modal-container input:focus {
  border: 1px solid #008080;
  outline: 2px solid #008080;
}
.close-edit-modal {
  position: absolute;
  top: 8px;
  right: 12px;
  font-size: 24px;
  cursor: pointer;
}
.about-dark .close-edit-modal {
  color: #fff;
}
.edit-modal-container button {
  border: 0.5px solid rgb(66, 66, 66);
  border-radius: 5px;
  min-width: 6rem;
  cursor: pointer;
  margin: 0.5rem;
}
.about-light .edit-modal-container button {
  background-color: white;
  color: black;
}
.about-light .edit-modal-container button:hover {
  background-color: #008080;
  color: white;
}
.about-light .edit-modal-container {
  background-color: white;
}
.about-dark .edit-modal-container {
  background-color: rgb(34, 34, 34);
}
.edit-modal-container button:disabled {
  opacity: 0.3;
  background-color: rgb(89, 89, 89);
  cursor: default;
}
.about-light .edit-modal-container button:disabled:hover {
  opacity: 0.3;
  background-color: rgb(89, 89, 89);
  color: #000000;
  cursor: default;
}
@media only screen and (max-width: 600px) {
  .edit-modal-container {
    width: 95%;
  }
}
