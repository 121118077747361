.dropdown-container {
  position: fixed;
  transform: rotate(35deg);
  z-index: 5;
  width: 60rem;
  height: 50rem;
  box-shadow: 1px 1px 3px rgb(66, 66, 66);
  transition: top 800ms ease-in-out, right 800ms ease-in-out;
}
.dropdown-container-show {
  top: -28rem;
  right: -22rem;
}
.dropdown-container-hide {
  top: -50rem;
  right: -50rem;
}
.dropdown-container ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 37rem;
  transform: rotate(-35deg);
}
.dropdown-container li {
  list-style: none;
  text-align: center;
  margin-left: 12rem;
  font-size: 1.5rem;
  margin-top: 1rem;
}
.fa-times,
.drop-down-link {
  transition: 400ms;
}
.dark .drop-down-link {
  color: white;
}
.light .drop-down-link {
  color: black;
}
.is-active .drop-down-link {
  border-bottom: 1px solid #008080;
  box-shadow: 0 5px 5px -5px #008080;
}
.dropdown-container li:hover {
  color: #008080;
}
.drop-down-light {
  background-color: white;
}
.drop-down-dark {
  background-color: rgb(34, 34, 34);
}
.drop-menu-x {
  font-size: 2rem;
  margin-top: 2rem;
  margin-left: 12rem;
  cursor: pointer;
}
.drop-menu-x:hover {
  color: #008080;
}
