.header {
  top: 0;
  position: fixed;
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 30;
  box-shadow: 1px 1px 3px rgb(66, 66, 66);
}
.header-light {
  background-color: white;
}
.header-dark {
  background-color: rgb(34, 34, 34);
}
.header-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  display: inline-flex;
  margin-right: 2rem;
}
.header-content li {
  font-size: 1.75rem;
  list-style-type: none;
  transition: 400ms;
}
.link-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 3rem;
  height: 4rem;
  cursor: pointer;
}
a {
  text-decoration: none;
}

.link-container li:hover {
  color: #008080;
}
.is-active .link-container {
  margin-bottom: -1px;
  border-bottom-width: 100%;
  border-bottom: 1px solid #008080;
  box-shadow: 0 5px 5px -5px #008080;
}
.project-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 4rem;
  cursor: pointer;
}
.is-active .project-link {
  margin-bottom: -1px;
  border-bottom-width: 100%;
  border-bottom: 1px solid #008080;
  box-shadow: 0 5px 5px -5px #008080;
}
.header-content-light li {
  color: black;
}
.header-content-dark li {
  color: white;
}
.toggle-container {
  border-radius: 1rem;
  margin-left: 3rem;
  cursor: pointer;
}
.toggle-container-dark:hover {
  border: 2px solid #008080;
}
.toggle-container-light {
  border: 2px solid black;
  background-color: rgb(240, 240, 240);
}
.toggle-container-dark {
  border: 2px solid white;
  background-color: rgb(73, 73, 73);
}
.moon {
  font-size: 1.5rem;
  margin: 0.2rem;
  margin-left: 1.7rem;
  margin-right: 0.4rem;
  color: white;
  transform: rotate(360deg);
  transition: 600ms;
  cursor: pointer;
}
.sun {
  font-size: 1.5rem;
  margin: 0.2rem;
  margin-right: 1.7rem;
  margin-left: 0.4rem;
  color: rgb(228, 175, 2);
  transition: 600ms;
  cursor: pointer;
  text-shadow: 0 0 3px #000;
}
.fa-plus-minus {
  font-size: 1rem;
  margin-bottom: 0.1rem;
}
.projects-plus {
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
  transition: transform 400ms;
}
.projects-minus {
  font-size: 0.9rem;
  margin-bottom: 0.2rem;
  transform: rotateZ(-180deg);
  transition: transform 400ms;
}

/* BEGIN MOBILE NAV */
.bars {
  font-size: 3rem;
  position: fixed;
  right: 1rem;
  z-index: 30;
}
.bars-light {
  color: black;
}
.bars-dark {
  color: white;
}
.mobile-nav {
  position: fixed;
  z-index: 5;
  height: 60rem;
  width: 70rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  border: 0.5px solid rgb(66, 66, 66);
  box-shadow: 0.5px 0.5px 3px rgb(66, 66, 66);
  transition: transform 800ms ease-in-out, top 800ms ease-in-out, right 800ms ease-in-out;
  transform: rotate(60deg);
  overflow: hidden;
}
.mobile-link-container {
  margin: 0 auto;
  margin-bottom: 2rem;
  width: 60%;
}
.is-active .mobile-link-container li {
  margin-bottom: 3rem;
  box-shadow: 0px 5px 4px -4px #008080;
}
.sub-menu-mobile-link-container {
  margin: 0 auto;
  margin-bottom: 2rem;
  width: 100%;
}
.is-active .sub-menu-mobile-link-container li {
  box-shadow: 0px 5px 4px -4px #008080;
}
.light .mobile-nav {
  background-color: white;
}
.dark .mobile-nav {
  background-color: rgb(34, 34, 34);
}
.mobile-nav-hide {
  top: -52rem;
  right: -45rem;
  transform: rotate(30deg);
}
.mobile-nav-show {
  top: -30rem;
  right: -44rem;
}
.mobile-nav a {
  text-decoration: none;
}
.mobile-nav-content {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: 40rem;
  margin-left: 18rem;
  transition: rotate 600ms;
  transform: rotate(-60deg);
}
.mobile-nav-content li {
  list-style-type: none;
  margin-bottom: 3rem;
}
.mobile-nav-content-hide {
  margin-top: 30rem;
  transform: translateX(-20rem);
  margin-left: 30rem;
  transition: 600ms;
}
.mobile-nav-content-show {
  transform: translateX(0rem);
  transform: rotate(-60deg);
  /* margin-left: 0; */
}
.dark .mobile-nav-content li {
  color: white;
}
.light .mobile-nav-content li {
  color: black;
}
.dark .mobile-nav-content a {
  color: white;
}
.light.mobile-nav-content a {
  color: black;
}
.fa-dark {
  color: white;
}
.fa-light {
  color: black;
}
.mobile-sub-menu {
  transition: 400ms;
  transition-timing-function: ease-in-out;
}
.mobile-sub-menu li {
  font-size: 1rem;
}
.mobile-sub-menu-hide {
  height: 0;
  transform: scaleY(0);
}
.mobile-sub-menu-show {
  height: 100%;
  transform: scaleY(1);
}
.mobile-sub-menu ul {
  padding: 0;
}
@media only screen and (max-width: 775px) {
  .header-content li {
    display: none;
  }
  .toggle-container {
    position: fixed;
    left: 1rem;
  }
  .toggle-container {
    border-radius: 1rem;
    margin-left: 0;
  }
}
@media only screen and (min-width: 776px) {
  .bars {
    display: none;
  }
  .mobile-nav {
    display: none;
  }
}
