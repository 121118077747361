@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");
.home {
  display: flex;
  justify-content: center;
  width: 100vw;
  /* height: 100vh; */
}
.home-light {
  background-color: white;
}
.home-dark {
  background-color: rgb(34, 34, 34);
}
.home-light p {
  color: black;
}
.home-dark p {
  color: white;
}
.show-nav {
  filter: blur(5px);
}
.home-container {
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  line-height: 2rem;
  font-size: 1.5rem;
}
.home-container h2 {
  text-align: left;
}
.home-container-light {
  color: black;
}
.home-container-dark {
  color: white;
}
.image-container img {
  max-height: 30rem;
  max-width: 100%;
  border-radius: 100%;
  box-shadow: none;
}
.home-container .image-container img {
  box-shadow: none;
}
.text-container p {
  margin-top: -1rem;
}
.home-header {
  transition: 600ms;
}
.home-header-show {
  margin-top: 2rem;
}
.home-container h2 {
  font-weight: 400;
  margin-bottom: 3rem;
}
.home-header-hide {
  margin-top: 2rem;
  transform: translateX(100rem);
}
.goodbye-header {
  transition: 600ms;
  margin-top: -7rem;
}
.goodbye-header-show {
  transform: translateX(0rem);
}
.goodbye-header-hide {
  transform: translateX(-100rem);
}
.home-about-text {
  margin-top: 1rem;
}
@media only screen and (max-width: 600px) {
  .home {
    overflow: hidden;
    margin: 0;
  }
  .image-container img {
    width: 90%;
  }
  .home-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }
  .home-container p {
    font-size: 1rem;
    text-align: left;
    width: 90%;
    margin: 0 auto;
    margin-top: -2rem;
  }
  .home-container h2 {
    margin-left: 1rem;
  }
  .goodbye-header {
    margin-top: -10rem;
  }
}

@media only screen and (min-width: 600px) {
  .menu-icon {
    display: none;
  }
  .text-container {
    width: 80%;
  }
  .image-container img {
    max-height: 20rem;
    box-shadow: 1px 1px 3px rgb(66, 66, 66);
    margin: 0;
  }
  .home-container {
    display: flex;
    flex-direction: column;
    height: 40rem;
  }
  .text-container {
    margin-bottom: 2rem;
  }
}
@media only screen and (min-width: 1000px) {
  .inner-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0.5px solid rgb(66, 66, 66);
    border-radius: 5px;
    min-height: 40rem;
  }
  .image-container img {
    max-height: 20rem;
  }
  .about-header {
    max-width: 30rem;
  }
  .text-container {
    width: 40%;
  }
  .image-container {
    margin-right: 3rem;
  }
  .text-container {
    margin-right: 2rem;
  }
}

@media only screen and (min-width: 600px) {
  .home-container {
    border: 0.5px solid rgb(66, 66, 66);
    border-radius: 5px;
    min-height: 40rem;
  }
}
@media only screen and (min-width: 1200px) {
  .text-container {
    width: 40%;
  }
  .image-container img {
    max-height: 26rem;
  }
}
@media only screen and (min-width: 1400px) {
  .image-container img {
    min-height: 26rem;
    max-height: 30rem;
  }
}
@media only screen and (min-width: 1600px) {
  .home-container {
    min-height: 50rem;
  }
  .text-container {
    width: 30%;
  }
  .image-container img {
    max-height: 30rem;
  }
}
@media only screen and (max-width: 1200px) and (min-height: 900px) {
  .home-container {
    height: 50rem;
  }
}
@media only screen and (min-width: 400px) {
  .home-about-text {
    display: none;
  }
}
