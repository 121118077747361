.about {
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
  width: 100vw;
  min-height: 100vh;
}
.resume {
  margin-top: 1rem;
  max-width: 95%;
}
.about-light {
  background-color: white;
}
.about-dark {
  background-color: rgb(34, 34, 34);
}
.show-nav {
  filter: blur(5px);
}
.about-container {
  display: flex;
  width: 80%;
  justify-content: center;
  line-height: 2rem;
  font-size: 1rem;
  text-align: left;
}
.resume-container img {
  max-height: 50rem;
}
.skills-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.skills-container div {
  width: 30%;
  min-width: 170px;
}
.skills-header {
  margin-bottom: 0rem;
}
.skills-container ul h3 {
  margin-left: -1rem;
  margin-bottom: 0.5rem;
}
.skills-container li {
  margin-left: 1rem;
}
.about-container-light {
  color: black;
}
.about-container-dark {
  color: white;
}
.about-dark textarea {
  background-color: rgb(34, 34, 34);
  color: white;
}
.about-text-container textarea {
  width: 100%;
  line-height: 2rem;
  font-size: 1rem;
  min-height: 14rem;
  margin-top: -1rem;
}
.about-text-container p {
  margin-top: -1rem;
  white-space: pre-line;
}
.about-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}
.skills-header-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 1000px) {
  .skills-header-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .skills-select-container {
    margin-top: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .skills-header-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
.skills-select-container {
  display: flex;
  align-items: center;
}
.actions-container {
  display: flex;
}
.skills-container h3 {
  margin-bottom: -1rem;
}
.add-a-new-skill-container h4 {
  margin: 0.5rem;
}
.skills-select-container span {
  margin-right: 1rem;
}
.skills-select-container button {
  height: 2.5rem;
  min-width: 5rem;
  margin-right: 1rem;
  cursor: pointer;
}
.skills-select-container select:focus {
  border: 1px solid #008080;
  outline: 1px solid #008080;
}
.about-container-light button:hover {
  background-color: #008080;
  color: white;
}
.actions-container button {
  height: 2.5rem;
  min-width: 5rem;
  margin-right: 1rem;
  cursor: pointer;
}
.skills-header-container select {
  height: 2.5rem;
  min-width: 5rem;
  border-radius: 5px;
  padding: 0.5rem;
  border: 0.5px solid rgb(66, 66, 66);
}
.no-skills {
  list-style: none;
  padding: 0;
  font-size: 2rem;
}
.about-dark .skills-header-container select {
  background-color: rgb(34, 34, 34);
  color: white;
  text-align: center;
  cursor: pointer;
}
.skills-header-container select:hover {
  border: .5px solid #008080;
  
}
.about-header-container button {
  height: 2.5rem;
  min-width: 5rem;
  cursor: pointer;
}
.add-a-new-skill-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
}
.add-a-new-skill-container form {
  width: 100%;
}
.add-a-new-skill-container h4 {
  cursor: pointer;
  text-decoration: underline;
}
.list-delete-mode,
.list-edit-mode {
  cursor: pointer;
  list-style: none;
  list-style-type: none;
}
.list-delete-mode:before {
  content: "❌";
  margin-right: 0.6rem;
  margin-left: -1.85rem;
}
.list-edit-mode:before {
  content: "✏️";
  margin-right: 0.6rem;
  margin-left: -1.85rem;
}
.add-a-new-skill-container button {
  margin-bottom: 2rem;
  cursor: pointer;
}
.about-container button:disabled {
  opacity: 0.3;
  background-color: rgb(89, 89, 89);
  cursor: default;
}
.skill-type-check-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}
.skill-type-check-container label {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.skill-type-check-container input {
  width: 2rem;
}
.about-container textarea {
  border-radius: 5px;
  border: 1px solid #008080;
}
.about-container textarea:focus {
  border-radius: 5px;
  border: 1px solid #008080;
  outline: 2px solid #008080;
}
.add-a-new-skill-container input[type="text"]:focus {
  border: 1px solid #008080;
  outline: 2px solid #008080;
}
.skill-type-check-container input[type="radio"] {
  accent-color: #008080;
  cursor: pointer;
  }

@media only screen and (max-width: 600px) {
  .about-container {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
  .about-header-container button {
    margin-right: 3.5rem;
  }
  .about-text-container textarea {
    width: 95%;
    margin: 0 auto;
    margin-top: -1rem;
  }
}

@media only screen and (min-width: 600px) {
  .about-text-container {
    width: 90%;
    margin: 0 auto;
  }
  .image-container img {
    max-height: 20rem;
  }
  .about-container {
    width: 80%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    border: 0.5px solid rgb(66, 66, 66);
    border-radius: 5px;
  }
}
@media only screen and (min-width: 1200px) {
  .about-text-container {
    padding-top: 0;
    padding-bottom: 0;
    width: 90%;
    margin-bottom: 3rem;
  }
  .about-container {
    flex-direction: row;
  }
}
@media only screen and (min-width: 1600px) {
  .about-text-container {
    width: 60%;
  }
  .image-container img {
    max-height: 30rem;
  }
}
.resume {
  display: none;
}
