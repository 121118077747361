@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap");
.contact {
  width: 100vw;
  height: 100vh;
}
.contact-light {
  background-color: white;
}
.contact a {
  font-size: 4.5rem;
}
.dark .contact {
  background-color: rgb(34, 34, 34);
}
.dark .contact a {
  text-decoration: none;
  color: white;
}
.light .contact a {
  text-decoration: none;
  color: black;
}
.contact-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.contact-header {
  font-size: 2.4rem;
}
.contact-header-light {
  color: black;
}
.dark .contact-header {
  color: white;
}
.connect-message {
  margin-top: -1rem;
}
.form-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.form-container label:last-of-type {
  width: 100%;
}
label {
  display: flex;
  flex-direction: column;
  text-align: left;
}
input {
  height: 1.5rem;
  width: 20rem;
  margin-bottom: 1rem;
}

.form-container textarea {
  height: 4rem;
  width: 100%;
}
.submit-button {
  margin-top: 0.5rem;
  border: none;
  border-radius: 5px;
  background-color: rgb(34, 34, 34);
  font-size: 1.2rem;
  height: 3rem;
  color: white;
  cursor: pointer;
  border: 0.5px solid rgb(66, 66, 66);
}
.contact-link-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contact-link-container p {
  font-size: 1rem;
  margin-bottom: -1rem;
}
.contact-link-container a {
  transition: color 400ms;
  font-weight: 700;
}
.light .contact-link-container a {
  color: #666;
}
.contact-link-container a:hover {
  color: #008080;
}
.icon {
  margin-top: -2rem;
  font-size: 3rem;
}

@media only screen and (max-width: 600px) {
  input,
  select,
  textarea {
    font-size: 16px;
  }
  .contact-container {
    width: 90%;
  }
  .contact-links {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .contact-link-container {
    width: 25%;
  }
  .contact-container p {
    font-size: 1rem;
    margin-top: 0rem;
    margin-bottom: 16px;
  }
  .icon {
    font-size: 4rem;
  }
  .contact-link-container {
    margin: 0.5rem;
  }
}
@media only screen and (min-width: 600px) {
  .contact-container {
    width: 80%;
    border: 0.5px solid rgb(66, 66, 66);
    border-radius: 5px;
    min-height: 40rem;
  }
  .contact-container .connect-message {
    font-size: 1.5rem;
    width: 80%;
  }
  .contact-links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -1.5rem;
    width: 80%;
    max-width: 500px;
  }
  .submit-button:hover {
    opacity: 0.7;
  }
}

@media only screen and (min-width: 1000px) {
  .contact-container {
    width: 80%;
    min-height: 40rem;
  }
  input,
  textarea {
    width: 26rem;
  }
  label {
    font-size: 1.4rem;
  }
  .contact-container .connect-message {
    width: 60%;
  }
}
@media only screen and (max-width: 1200px) and (min-height: 700px) {
  .contact-container .connect-message {
    width: 60%;
  }
}
