.project {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 2rem;
  position: relative;
}
.project img {
  cursor: pointer;
}
.background-light {
  background-color: white;
}
.background-light img {
  border: 1px solid rgb(34, 34, 34);
}
.project-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 80%;
  margin: auto;
  margin-bottom: 16px;
}
.project-container p {
  width: 90%;
  margin: 0 auto;
  font-size: 16px;
}
.project-image-container p {
  margin-bottom: 1rem;
}
.project-image-container {
  position: relative;
}
.each-project-image-container {
  position: relative;
  margin-bottom: 24px;
}
.click-to-enlarge-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(34, 34, 34);
  border-radius: 5px;
  z-index: 5;
  min-width: 200px;
  display: none;
  cursor: pointer;
  font-weight: 900;
  background-color: #008080;
}
.click-to-enlarge-container p {
  color: #fff;
  padding: 8px 16px;
  margin-bottom: 0;
}

.project-container img {
  max-width: 90%;
  border-radius: 5px;
}
.outer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;
  margin-bottom: 16px;
}
.project-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: auto;
}
.project-content a {
  text-decoration: underline;
}
.project-header h2 {
  font-family: Roboto;
}
.text-light a {
  color: black;
}
.project-header {
  margin-top: 3rem;
}
.sample-screenshots-container {
  padding-bottom: 16px;
  margin-top: 24px
}
.sample-screenshots-container p {
  margin-bottom: 4px;
}
.dark .text-container a {
  color: white;
}
.light .text-container a {
  color: black;
}
.dark .text-container a:hover,
.light .text-container a:hover {
  color: #008080;
}
@media only screen and (max-width: 600px) {
  .project img {
    cursor: default;
  }
}
@media only screen and (max-width: 1000px) {
  .view-project-text-desktop {
    display: none;
  }
}
@media only screen and (min-width: 600px) {
  .project-container {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    border: 0.5px solid rgb(66, 66, 66);
    border-radius: 5px;
  }
  .project-image-container:hover img,
  .each-project-image-container:hover img {
    opacity: 0.8;
  }
  .project-image-container:hover > .click-to-enlarge-container, 
  .each-project-image-container:hover > .click-to-enlarge-container {
  display: flex;
  }
}
@media only screen and (min-width: 1000px) {
  .project {
    min-height: 100vh;
  }
  .project-container {
    min-height: 40rem;
  }
  .project-content {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin-top: 2rem;
  }
  .project-image-container {
    width: 60%;
    padding: 0;
    margin-bottom: 16px;
    position: relative;
  }
  .text-container {
    text-align: left;
    margin: 0;
    width: 40%;
    font-size: 1.2rem;
  }
  .sample-screenshots-container {
    width: 70%;
  }
}

@media only screen and (min-width: 1200px) {
  .project-container {
    width: 80%;
  }
  .project-header {
    font-size: 2.4rem;
    margin-bottom: 0rem;
  }
  .project-image-container {
    padding: 0;
  }
  .project-content {
    margin-top: 4rem;
  }
  .project-container img {
    max-width: 90%;
  }
}
@media only screen and (min-width: 1600px) {
  .project-image-container {
    width: 50%;
  }
  .project-container {
    min-height: 50rem;
  }
}
@media only screen and (max-width: 1200px) and (min-height: 900px) {
  .project-container {
    min-height: 50rem;
    margin-top: 2rem;
  }
}
